<template>
  <div>
    <h1>My bookings</h1>

    <div
      v-if="isTableLoading"
      class="text-center pt-3"
    >
      <b-spinner label="Loading..." />
    </div>

    <template v-else>
      <vue-good-table
        class="mt-3"
        :columns="columns"
        :rows="bookings"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        @on-row-click="rowPaymentHistory"
      >
        <template slot="table-column" slot-scope="props">
          <span
            v-if="props.column.label === 'Paid'"
            class="d-flex align-items-center"
          >
            <span class="mr-25">
              {{props.column.label}}
            </span>
            <feather-icon
              icon="InfoIcon"
              size="16"
              v-b-tooltip.hover
              class="text-primary"
              title="Click on a row in this column to see payment history"
            />
          </span>

          <span
            v-else-if="props.column.label === 'Captain'"
            class="d-flex align-items-center"
          >
            <span class="mr-25">
              {{props.column.label}}
            </span>
            <feather-icon
              icon="InfoIcon"
              size="16"
              v-b-tooltip.hover
              class="text-primary"
              title="Click on a picture in this column to see captain's profile"
            />
          </span>

          <span
            v-else-if="props.column.label === 'Boat'"
            class="d-flex align-items-center"
          >
            <span class="mr-25">
              {{props.column.label}}
            </span>
            <feather-icon
              icon="InfoIcon"
              size="16"
              v-b-tooltip.hover
              class="text-primary"
              title="Click on a link in this column to see boat details"
            />
          </span>
          <span v-else>
              {{props.column.label}}
          </span>
        </template>
        <template slot="column-filter" slot-scope="{ column, updateFilters }">
          <b-form-datepicker
            v-if="column.filterOptions ? column.filterOptions.customFilter : false"
            locale="en"
            size="sm"
            placeholder=""
            reset-button
            @input="(value) => updateFilters(column, value)"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Paid -->
          <span v-if="props.column.field === 'status'" @click="fieldPaymentHistory(props.row.id)">
            <b-badge v-if="props.row.boat_id > 0" :variant="status[props.row.status].class">
              {{ status[(props.row.status == 7 ? 4 : props.row.status)].status }}
            </b-badge>
            <b-badge v-else :variant="statusInshore[props.row.status].class">
              {{ statusInshore[(props.row.status== 7 ? 4 : props.row.status)].status }}
            </b-badge>
          </span>

          <!-- Column: Survey -->
          <span v-else-if="props.column.field === 'survey'">
            <feather-icon v-if="props.row.boat_id > 0"
              :icon="props.row.survey ? 'CheckIcon' : 'XIcon'"
              size="16"
            />
          </span>

          <!-- Column: Captain -->
          <span v-else-if="props.column.field === 'captain'">
            <b-img v-if="props.row.boat_id > 0"
              v-b-modal.captain-modal
              v-bind="imgSettings"
              :src="props.row.captain"
              rounded="circle"
              alt="Captain's profile image"
              @click="openCaptainModal(props.row.boat_id, props.row.captain)"
            />
          </span>

          <span v-else-if="props.column.field === 'boat'">
            <b-link
              v-b-modal.boat-modal
              href="#"
              @click="openBoatModal(props.row.boat_id)"
            >
              {{ props.row.boat }}
            </b-link>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
            class="d-flex flex-nowrap justify-content-end"
          >
            <b-button v-if="props.row.boat_id > 0"
              class="mr-25"
              variant="success"
              size="sm"
              :to="`/charter-survey/${props.row.id}`"
              :disabled="!!props.row.cancelled"
            >
              <span class="text-light">Preferences</span>
            </b-button>
            <b-button
              v-if="props.row.boat_id > 0 && props.row.status !== 4 && props.row.status !== 6 && props.row.status !== 7"
              v-b-modal.payment-modal
              class="mr-25"
              variant="success"
              size="sm"
              :disabled="!!props.row.cancelled || props.row.status === 6"
              @click="openPaymentModal(props.row)"
            >
              <span class="text-light">Payments</span>
            </b-button>
            <b-button
              v-b-modal.details-modal
              class="mr-25"
              variant="success"
              size="sm"
              :disabled="!!props.row.cancelled"
              @click="openDetailsModal(props.row.id)"
            >
              <span class="text-light">Details</span>
            </b-button>
            <b-button v-if="props.row.boat_id > 0"
              v-b-modal.edit-modal
              variant="success"
              size="sm"
              :disabled="!!props.row.cancelled"
              @click="openEditModal(props.row)"
            >
              <span class="text-light">Edit</span>
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-end flex-wrap">
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </template>
      </vue-good-table>
    </template>

    <b-modal
      id="details-modal"
      ok-only
      title="Booking Details"
      cancel-variant="outline-secondary"
    >
      <div
        v-if="isModalLoading"
        class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <template
        v-for="(value, key, index) in modal"
        v-else
      >
        <b-form-group
          v-if="value"
          :id="`input-group-${key}`"
          :key="index"
          :label="key"
          :label-for="`input-${key}`"
          class="capitalize"
        >
          <b-form-input
            :id="`input-${key}`"
            :value="value"
            readonly
          />
        </b-form-group>
      </template>
    </b-modal>

    <b-modal
      id="payment-modal"
      :title='"Make Payment #" + modalBookingId'
      hide-footer
      cancel-variant="outline-secondary"
    >
      <div
        v-if="user && !user.card"
        class="align-items-center d-flex position-absolute text-center payment-setup-card"
      >
        <h3>
          You haven't chosen your payment method. Please make it on 
          <b-link :to="{ name: 'profile' }">profile page</b-link>
        </h3>
      </div>

      <div :class="['d-flex','justify-content-between']" text-variant="primary">
        <span>{{ 'Total' }}</span>
        <strong>${{ paymentPrices['total'] }}</strong>

      </div>
      <hr>

        <div :class="['d-flex', 'justify-content-between', (paymentPrices.paidDeposit > 0 ? 'payment-paid' : 'payment-active')]" text-variant="primary">
            <span>Deposit</span>
            <strong>${{paymentPrices.paidDeposit > 0 ? paymentPrices.paidDeposit : paymentPrices.deposit}}</strong>
        </div>
        <hr>

        <div :class="['d-flex', 'justify-content-between', (paymentPrices.paidSecond > 0 ? 'payment-paid' : (paymentPrices.paidDeposit > 0 ? 'payment-active' : '') )]" text-variant="primary">
            <span>Second payment</span>
            <strong>${{paymentPrices.paidSecond > 0 ? paymentPrices.paidSecond : paymentPrices.second}}</strong>
        </div>
        <hr>

        <div v-if="paymentPrices['paid'] > (paymentPrices.paidSecond + paymentPrices.paidDeposit)">
            <div :class="['d-flex', 'justify-content-between', 'payment-paid']" text-variant="primary">
                <span>Additional payments</span>
                <strong>${{ paymentPrices['paid'] - (paymentPrices.paidSecond + paymentPrices.paidDeposit) }}</strong>
            </div>
            <hr>
        </div>

        <div :class="['d-flex', 'justify-content-between', (paymentPrices.remaining <= 0 ? 'payment-paid' : (paymentPrices.paidSecond > 0 ? 'payment-active' : '') )]" text-variant="primary">
            <span>Final payment</span>
            <strong>${{ (paymentPrices.remaining != paymentPrices.final && paymentPrices.paidDeposit > 0 && paymentPrices.paidSecond > 0) ? paymentPrices.remaining : paymentPrices.final}}</strong>
        </div>
        <hr>

      <div :class="['d-flex','justify-content-between', 'payment-active']" text-variant="primary">
        <span>{{ 'Paid' }}</span>
        <strong>{{paymentPrices['paid'] > 0 ? '-' : ''}}${{ paymentPrices['paid'] }}</strong>
      </div>
      <hr>
      <!--<div :class="['d-flex','justify-content-between', 'payment-active']" text-variant="primary">
        <span>{{ 'Refunded' }}</span>
        <strong>${{ paymentPrices['refunded'] }}</strong>
      </div>
      <hr>-->
      <!--<div :class="['d-flex','justify-content-between', 'payment-remaining']" text-variant="primary">
        <span>{{ 'Remaining' }}</span>
        <strong>${{ paymentPrices['remaining'] }}</strong>
      </div>-->
      <template v-if="showTipAbility">
        <h6 class="mt-3">
          Tip your captain! (optional, industry standard is 20%)
        </h6>
        <b-row class="mb-1">
          <b-col cols="8">
            <b-input-group append="%">
              <b-form-input
                      v-model="tipAmount"
                      list="tips-datalist"
                      type="number"
                      placeholder="Tip Percentage"
                      :min="1"
                      :max="100"
              />
            </b-input-group>
            <b-form-datalist id="tips-datalist">
              <option
                      v-for="(tipOption, index) in tipOptions"
                      :key="index"
              >
                {{ tipOption }}
              </option>
            </b-form-datalist>
          </b-col>
          <b-col
                  cols="4"
                  class="align-self-center"
          >
            {{ tipFinalResult.text }}
          </b-col>
        </b-row>
      </template>
      <b-button v-if="paymentPrices['remaining'] > 0"
        block
        class="mt-3 mb-1"
        variant="success"
        :disabled="isPaymentLoading || (user && !user.card)"
        @click="makePayment"
      >
        <b-spinner
          v-if="isPaymentLoading"
          label="Spinning"
          small
        />
        <span v-else>
          Pay ${{ toPayNow }} {{ tipFinalResult.amount >= 0 && showTipAbility ? `+ $${tipFinalResult.amount} Tip` : '' }}
        </span>
      </b-button>
      <h5 v-else class="text-danger">
        Please contact your Captain for the final cost of your trip
      </h5>


      <p>
        <b-link :to="{ name: 'profile' }">Use new card</b-link>. We will charge your default payment method.
      </p>

    </b-modal>

    <b-modal
      id="captain-modal"
      ok-only
      title="Captain's Profile"
      cancel-variant="outline-secondary"
    >
      <div
        v-if="isCaptainLoading"
        class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <template
        v-else
      >
        <div class="d-flex flex-wrap flex-sm-nowrap">
          <b-img
            :src="captain.picture"
            rounded="circle"
            alt="Captain's profile image"
            class="profile-image mr-2"
          />
          <div class="w-100 mt-2 mt-sm-0">
            <b-form-group
              label="Name"
              class="capitalize"
            >
              <b-form-input
                v-model="captain.name"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Email"
              class="capitalize"
            >
              <b-form-input
                v-model="captain.email"
                readonly
              />
            </b-form-group>
          </div>
        </div>
        <b-row class="mt-1">
          <b-col>
            {{ captain.bio }}
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal
      id="boat-modal"
      title="Boat Details"
      cancel-variant="outline-secondary"
    >
      <div
        v-if="isBoatLoading"
        class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <template
        v-else
      >
        <img
          :src="boat.picture"
          alt="Boat image"
          class="img-fluid"
        />
        <div class="px-2 pt-1">
          <h4>{{ boat.name }}</h4>
          <p class="card-title">
            {{ boat.description }}
          </p>
        </div>
        <b-list-group flush>
          <b-list-group-item>
            <strong>Length/Tonnage:</strong> {{ boat.length }} foot / {{ boat.tonnage }} Gross Tons
          </b-list-group-item>
          <b-list-group-item>
            <strong>Capacity:</strong> {{ boat.capacity }} Passengers
          </b-list-group-item>
          <b-list-group-item>
            <strong>Specialty:</strong> {{ boat.specialty }}
          </b-list-group-item>
        </b-list-group>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="float-right"
          @click="$bvModal.hide('boat-modal')"
        >
          Close
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :to="{ name: 'trip-preparations' }"
        >
          Trip Preparations
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="edit-modal"
      :title='"Edit booking details #" + edited.id'
    >
      <b-form-group
        label="Status"
        label-for="status"
      >
        <b-form-checkbox
          v-model="edited.cancelled"
          switch
        >
          Cancel this booking?
        </b-form-checkbox>
      </b-form-group>
        <template v-if="!edited.cancelled">


            <b-form-group label="Boat:" label-for="input-boat" v-show="edited.editFieldsHide">
                <validation-provider #default="{ errors }" name="boat_id" rules="required">
                    <v-select
                            :options="boatNamesTripsPictures"
                            :items="boatNamesTripsPictures"
                            v-model="edited.boatObject"
                            item-text="name"
                            item-value="id"
                            label="name"
                            :clearable="false"
                            required
                            @input="boatChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group label="Charter Package:" label-for="trip" v-show="edited.editFieldsHide">
                <validation-provider #default="{ errors }" name="charter package" rules="required">
                    <v-select
                            id="trip"
                            v-model="edited.charterPackage"
                            placeholder="Select package"
                            label="text"
                            :options="packages"
                            :clearable="false"
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <b-form-group label="Number of guests" label-for="details">
                <v-select
                        v-model="edited.details"
                        placeholder="Enter guests number"
                        :options="guestsOptions"
                        :clearable="false"
                />
            </b-form-group>

            <b-form-group label="Date" v-show="edited.editFieldsHide">
                <b-form-datepicker
                        id="start_at-datepicker"
                        v-model="edited.date"
                        :min="minDate"
                        :date-disabled-fn="dateDisabled"
                        :disabled="isDatepickerDisabled"
                        :placeholder="datepickerPlaceholder"
                        locale="en"
                        @input="dateChanged"
                />
            </b-form-group>
            <template v-if="edited.boatObject !=null && edited.boatObject.id === TEASER_ID && (edited.charterPackage === HALF_DAY_TRIP || edited.charterPackage === HARBOR_CRUISE)" >
                <b-form-group label="Choose the time" v-show="edited.editFieldsHide">
                    <v-select
                            id="select-charter-package"
                            v-model="edited.time"
                            placeholder="Select package"
                            label="text"
                            :options="times"
                            :clearable="false"
                            :selectable="timesDisabled"
                            :disabled="isDatepickerDisabled"
                    />
                </b-form-group>
            </template>
            <template v-if="!edited.editFieldsHide">
                Please contact us to make additional changes on this booking.
            </template>
        </template>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          class="float-right"
          @click="$bvModal.hide('edit-modal')"
        >
          Close
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isEditLoading"
          @click="editSubmit"
        >
          <b-spinner
            v-if="isEditLoading"
            label="Spinning"
            small
          />
          <span v-else>
            Save
          </span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="payment-history-modal"
      ok-only
      title="Payment History"
      size="lg"
    >
      <div
        v-if="isPaymentHistoryLoading"
        class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
        <div v-else-if="paymentsHistory.length">
            <b-table
                    borderless
                    table-variant="light"
                    hover
                    :fields="paymentsHistoryFields"
                    :items="paymentsHistory"
            />
            <hr/>
            <div>
                <span>Total paid: ${{paymentsHistoryPaid}}</span>
                <span v-if="paymentsHistoryRefunded>0">
                    <br/>
                    Total refunded: ${{paymentsHistoryRefunded}}
                </span>
            </div>
        </div>
      <h4
        v-else
        class="text-center"
      >
        There are no payments.
      </h4>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations'
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormDatalist,
  BImg,
  BLink,
  BListGroup,
  BListGroupItem,
  BFormDatepicker,
  BFormCheckbox,
  BBadge,
  BTable,
  VBTooltip,
  BInputGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import axios from '@/libs/axios'
import { toastSuccess, toastFailure } from '@/libs/toastification';
import {sweetWarning, sweetError} from "@/libs/sweet-alerts";
import {checkReservedDate, isTimeSelectable} from "@/libs/reserved-dates";
import {HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID} from "@/constants/trip-types.enum";
import vSelect from 'vue-select'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormDatalist,
    BImg,
    BLink,
    BListGroup,
    BListGroupItem,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BTable,
    BInputGroup,
  },
    data() {
        return {
          HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID,
          required,
          packages: null,
          minDaysToChangeHalfDayTrip: 14,
          thisBoatTripNameId:[],
          isTableLoading: true,
            daysDiff:null,
            columns: [
        {
          label: 'Charter #',
          field: 'id',
          type: 'number',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: 'Date',
          field: 'date',
          width: '140px',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'yyyy-MM-dd',
          filterOptions: {
            customFilter: true
          },
        },
        {
          label: 'Time',
          field: 'time',
        },
        {
          label: 'Paid',
          field: 'status',
          sortable: false,
          tdClass: "payment-status",
          filterOptions: {
            enabled: true,
            placeholder: 'All',
            filterDropdownItems: [
              {
                value: 0,
                text: 'Only created',
              },
              {
                value: 2,
                text: 'New Booking',
              },
              {
                value: 3,
                text: 'Partly Paid',// '50% Paid',
              },
              {
                value: 4,
                text: 'Fully Paid',
              },
              {
                value: 6,
                text: 'Complete',
              },
              {
                value: 1,
                text: "Cancelled",
              },
              {
                value: 7,
                text: "Overpaid",
              },
            ],
            trigger: 'enter',
          },
        },
        {
          label: 'Charter Survey',
          field: 'survey',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'All',
            filterDropdownItems: [
              {
                value: 0,
                text: 'Not completed',
              },
              {
                value: 1,
                text: 'Completed',
              },
            ],
            trigger: 'enter',
          },
        },
        {
          label: 'Captain',
          field: 'captain',
          sortable: false,
        },
        {
          label: 'Boat',
          field: 'boat',
          filterOptions: {
            enabled: true,
            placeholder: 'All',
            trigger: 'enter',
          },
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-right pr-1',
          tdClass: 'text-right',
          sortable: false,
        },
      ],
      statusInshore:{
        0: {
          id: 0,
          status: "Only created",
          class: "light-primary",
        },
        1: {
          id: 1,
          status: "Cancelled",
          class: "light-dark",
        },
        2: {
          id: 2,
          status: "Pending",
          class: "light-success",
        },
        3: {
          id: 3,
          status: "- 3 -",
          class: "light-warning",
        },
        4: {
          id: 4,
          status: "- 4 -",
          class: "light-danger",
        },
        5: {
          status: "- 5 -",
        },
        6: {
          status: "Contracted",
        },
        7: {
          status: "Overpaid",
          class: "light-danger",
        },
      },
      status: {
        0: {
          id: 0,
          status: "Only created",
          class: "light-primary",
        },
        1: {
          id: 1,
          status: "Cancelled",
          class: "light-dark",
        },
        2: {
          id: 2,
          status: "New Booking",
          class: "light-success",
        },
        3: {
          id: 3,
          status: "Partly Paid",// "50% Paid",
          class: "light-warning",
        },
        4: {
          id: 4,
          status: "Fully Paid",
          class: "light-danger",
        },
        5: {
          status: "Captain Tip",
        },
        6: {
          status: "Complete",
        },
        7: {
          status: "Overpaid",
          class: "light-danger",
        },
      },
      pageLength: 10,
      isModalLoading: true,
      modal: {
        boat: null,
        package: null,
        date: null,
        time: null,
        name: null,
        email: null,
        phone: null,
        details: null,
      },
      modalBookingId: 0,
      payments: [
        {
          name: 'Deposit',
          key: 'deposit',
          status: 2,
        },
        {
          name: 'Second payment',
          key: 'second',
          status: 3,
        },
        {
          name: 'Final payment',
          key: 'final',
          status: 4,
        },
      ],
      toPayNow: 0,
      alreadyPaid: 0,
      refunded: 0,
      paidDeposit: 0,
      paidSecond: 0,
      paidFinal: 0,
      paidTip: 0,
      paidCustom: 0,
      depositPrice: 0,
      totalPrice: 0,
      currentPrice: 0,
      tipAmount: 0,// 10,
      tipOptions: [10, 15, 20],
      isTipLoading: false,
      isPaymentLoading: false,
      imgSettings: {
        width: 50,
        height: 50,
      },
      isCaptainLoading: false,
      captain: {
        name: '',
        email: '',
        bio: '',
        picture: '',
      },
      isBoatLoading: false,
      boat: {
        picture: null,
        name: null,
        description: null,
        length: null,
        tonnage: null,
        capacity: null,
        specialty: null,
      },
      isEditLoading: false,
      edited: {
        id: null,
        boat: null,
        date: null,

        beforeDate: null,
        beforeBoat: null,
        beforeCharterPackage: null,

        charterPackage: null,
        status: null,
        time: null,
        beforeTime: null,
        cancelled: false,
        trip_id:null,
        details:null,
        boatCapacity:null,
        boatObject:null,
        editFieldsHide:true
      },
      minDate: new Date(),
      blockDates: [],
      datepickerPlaceholder: 'No date selected',
      isDatepickerDisabled: false,
      isPaymentHistoryLoading: true,
      paymentsHistoryFields: [
        {
          key: 'status',
          formatter: value => {
            return this.status[value].status
          }
        },
        'date',
        'paid',
        'method',
      ],
        paymentsHistory: [],
        paymentsHistoryPaid: 0,
        paymentsHistoryRefunded: 0,
    }
  },
  computed: {
    ...mapGetters({
      bookings: 'bookings/myBookingsWithPayments',
      user: 'auth/user',
      boatNames: 'boats/boatNames',
      boatNamesTripsPictures: 'boats/boatNamesTripsPictures',
    }),
    guestsOptions(){
      const options = [];
      for( let i=1; i <= this.edited.boatCapacity; i++ ){
        options.push(i);
      }
      return options;
    },
    showTipAbility() {
      const foundBooking = this.bookings.find(booking => booking.id === this.modalBookingId)
      if (foundBooking) return foundBooking.status >= 3
      return false
    },
    tipFinalResult() {
      if (!this.showTipAbility) this.tipAmount = 0

      const result = Math.round(this.totalPrice * (parseInt(this.tipAmount, 10) / 100))

      return {
        text: result ? (result > 0 ? `Amount the tip will be $${result}` : 'Enter a number between 1-100') : '',
        amount: result,
      }
    },
    paymentPrices() {

      const halfTotalPrice = this.totalPrice / 2 ;
      let balance = this.alreadyPaid - this.refunded;
      return {
        total: this.totalPrice,
        remaining: this.totalPrice - balance,
        refunded: this.refunded,
        paid: this.alreadyPaid,
        deposit: this.depositPrice,
        second: halfTotalPrice - this.depositPrice,
        final: halfTotalPrice,//(this.totalPrice - this.depositPrice) - halfTotalPrice,
        paidDeposit: this.paidDeposit,
        paidSecond: this.paidSecond,
        paidFinal: this.paidFinal,
        paidTip: this.paidTip,
        paidCustom: this.paidCustom
      }
    },
    times() {
      const timeOptions = [
        {
          value: "06:00:00",
          text: "6 AM",
          selectable: isTimeSelectable("6 AM", this.blockDates, this.edited),
        },
        {
          value: "12:00:00",
          text: "12 PM",
          selectable: isTimeSelectable("12 PM", this.blockDates, this.edited),
        }
      ];

      if (this.edited.charterPackage === HARBOR_CRUISE) {
        timeOptions.push({
          value: "18:00:00",
          text: "6 PM",
          selectable: isTimeSelectable("6 PM", this.blockDates, this.edited),
        });
      }

      return timeOptions;
    },
  },
  async mounted() {
    await this.loadMyBookingsWithPayments()
      .then(() => {
        this.loadBoatNames()
          .then(() => {
            this.$set(
                this.columns[6].filterOptions,
                'filterDropdownItems',
                this.boatNames
            );

            this.isTableLoading = false;
          })
          .then(()=>{
            this.loadBoatNamesTripsPictures();
          })
          .catch(() => { toastFailure('Something went wrong.') })
      })
      .catch(() => {
        toastFailure('Something went wrong.')
      })
  },
  watch: {
    'edited.boatObject.id': function () {
      this.loadBlockDates();
    },
    'edited.charterPackage': function () {
      this.loadBlockDates();
    },
  },
  methods: {
    ...mapActions({
      loadMyBookingsWithPayments: 'bookings/loadMyBookingsWithPayments',
      loadBoatNames: 'boats/loadBoatNames',
      updateMyBooking: 'bookings/updateMyBooking',
      makeBookingPayment: 'bookings/makeBookingPayment',
      loadBoatNamesTripsPictures: 'boats/loadBoatNamesTripsPictures',
    }),
    dateDiffInDays() {
        let _MS_PER_DAY = 1000 * 60 * 60 * 24;
        this.daysDiff = Math.floor((new Date(this.edited.date).getTime() - Date.now()) / _MS_PER_DAY);

        if( this.daysDiff < this.minDaysToChangeHalfDayTrip && this.edited.boat == TEASER_ID ){
            this.edited.editFieldsHide = false;
        }
    },
    showError(responseData=null, warning=false) {

      if( responseData != null && responseData.message != undefined && responseData.message != null ){

        let errorMSG = responseData.message + '</br>';
        if(responseData.errors){

          Object.keys(responseData.errors).map(function(errorKey){
            responseData.errors[errorKey].map(function(value, key){
              errorMSG += value + '</br>';
            });
          });
        }

        if( warning ){
          sweetWarning('Warning', errorMSG);
        }
        else{
          sweetError('Error', errorMSG);
        }
      }
      else{
        toastFailure("Something went wrong.");
      }
    },
    boatChanged() {

      const selectedBoatTripNameId = [];
      const packagesLocal = [];
      let that = this;
      this.boatNamesTripsPictures[this.edited.boatObject.id - 1].trips.map(function(trip){

        selectedBoatTripNameId[trip.name] = trip.id;
        if( trip.inactive === 0 ) {
          packagesLocal.push(trip.name);
        }
      });

      this.thisBoatTripNameId = selectedBoatTripNameId;

      this.packages = packagesLocal;
    },
    openDetailsModal(bookingId) {
      this.isModalLoading = true
      axios.get(`api/booking-details/${bookingId}`)
        .then(response => {
          this.modal = { ...response.data.data }
          this.isModalLoading = false
        })
        .catch(() => {
          toastFailure('Something went wrong.')
        })
    },
    openPaymentModal(booking) {

      let alreadyPaid = 0;
      let refunded = 0;
      let paidDeposit = 0;
      let paidSecond = 0;
      let paidFinal = 0;
      let paidTip = 0;
      let paidCustom = 0;

      booking.payments.map(function(payment){

        if( booking.payment_statuses['status_'+payment.status] != 'tip' ){
          alreadyPaid += payment.amount;
        }

        payment.refunds.map(function(refund){
          refunded += refund.amount;
        });

        if( booking.payment_statuses['status_'+payment.status] == 'deposit' ){
          paidDeposit += payment.amount;
        }
        else if(booking.payment_statuses['status_'+payment.status] == 'installment'){
          paidSecond += payment.amount;
        }
        else if(booking.payment_statuses['status_'+payment.status] == 'final'){
          paidFinal += payment.amount;
        }
        else if(booking.payment_statuses['status_'+payment.status] == 'tip'){
          paidTip += payment.amount;
        }
        else{
          paidCustom += payment.amount;
        }

      });

      this.paidDeposit = paidDeposit;
      this.paidSecond = paidSecond;
      this.paidFinal = paidFinal;
      this.paidTip = paidTip;
      this.paidCustom = paidCustom;

      this.refunded = refunded;
      this.alreadyPaid = alreadyPaid;
      this.tipAmount = 0; //10
      this.modalBookingId = booking.id;
      this.depositPrice = booking.charter_package === "Harbor Cruise" ? 300 : this.calculatePrice(booking) * .1;
      this.totalPrice = this.calculatePrice(booking);
      this.toPayNow = this.paymentPrices.paidDeposit == 0 ? this.paymentPrices.deposit : (this.paymentPrices.paidSecond == 0 ? this.paymentPrices.second : this.paymentPrices.remaining);
    },
    paymentDetails(status, key) {
      const foundBooking = this.bookings.find(booking => booking.id === this.modalBookingId)
      if (!foundBooking) {
        return {
          paid: true,
          active: true,
        }
      }

      let isActive = foundBooking.status + (foundBooking.status ? 1 : 2) === status
      if (isActive) {
        this.currentPrice = this.paymentPrices[key]
      }

      return {
        paid: foundBooking.status >= status,
        active: isActive,
      }
    },
    makePayment() {
      this.isPaymentLoading = true;
      const foundBooking = this.bookings.find(booking => booking.id === this.modalBookingId);

      if (this.tipFinalResult.amount > 0 && this.showTipAbility) {

        let makeTip = null;

        makeTip = axios.post('api/make-tip', {
          amount: this.tipFinalResult.amount,
          email: this.user.email,
          booking: this.bookings.find(booking => booking.id === this.modalBookingId).id,
        });

        Promise.all([makeTip])
          .then(() => {
            toastSuccess('Tip payment was successful.');
          })
          .catch((error) => {
            this.showError(error.response.data);
          })
          .finally(() => {
            this.isPaymentLoading = false
          });
      }

      this.makeBookingPayment({
        amount: this.toPayNow,
        email: this.user.email,
        status: foundBooking.status ? foundBooking.status : 1,
        booking: foundBooking.id,
      }).then((response) => {
        toastSuccess('Payment was successful.')
        this.$root.$emit('bv::hide::modal', 'payment-modal');
      })
      .catch((error) => {
        this.showError(error.response.data);
      })
      .finally(() => { this.isPaymentLoading = false });

      /*const makePayment = axios.post('api/make-payment', {
        amount: this.toPayNow,
        email: this.user.email,
        status: foundBooking.status ? foundBooking.status : 1,
        booking: foundBooking.id,
      });*/
    },
    openCaptainModal(boatId, picture) {
      this.isCaptainLoading = true
      axios.get(`api/captain/${boatId}`)
        .then(response => {
          this.captain = { ...response.data.data }
          this.captain.picture = picture
          this.isCaptainLoading = false
        })
        .catch(() => {
          toastFailure('Something went wrong.')
        })
    },
    openBoatModal(boatId) {
      this.isBoatLoading = true
      axios.get(`api/boats/${boatId}`)
        .then(response => {
          this.boat = { ...response.data.data }
          this.isBoatLoading = false
        })
        .catch(() => {
          toastFailure('Something went wrong.')
        })
    },
    openEditModal(row) {

        this.edited.editFieldsHide = true;
        this.edited.id = row.id
        this.edited.boat = row.boat_id
        this.edited.boatCapacity = row.boat_capacity
        this.edited.date = row.date
        this.edited.beforeDate = row.date;
        this.edited.beforeBoat = this.boatNamesTripsPictures.find(x => x.id === row.boat_id);
        this.edited.beforeCharterPackage = row.charter_package;
        this.edited.time = row.time
        this.edited.beforeTime = row.time
        this.edited.charterPackage = row.charter_package
        this.edited.status = row.status
        this.edited.trip_id = row.trip_id
        this.edited.cancelled = Boolean(row.cancelled),
        this.edited.details = row.details
        this.edited.boatObject = this.boatNamesTripsPictures.find(x => x.id === row.boat_id);

        this.dateDiffInDays();
        this.boatChanged();
        this.loadBlockDates();
    },
    async editSubmit() {
        this.isEditLoading = true

      if (this.edited.cancelled && !(await this.confirmationMessage())) {
        this.isEditLoading = false
        return
      }

      if( this.daysDiff < this.minDaysToChangeHalfDayTrip && this.edited.beforeBoat.id == TEASER_ID && this.edited.beforeCharterPackage != HALF_DAY_TRIP && this.edited.charterPackage == HALF_DAY_TRIP ){

          this.showError({message: 'Your trip cannot be changed to a 1/2 day trip  because we are less than '+this.minDaysToChangeHalfDayTrip+' days out.'});
          this.isEditLoading = false;
          return false;
      }

      if( this.edited.boatCapacity < this.edited.details ){
        this.showError({message: 'Max. guests number is '+this.edited.boatCapacity+' for this boat.'});
        this.isEditLoading = false;
        return false;
      }

      this.updateMyBooking({
        id: this.edited.id,
        date: this.edited.date,
        time: this.edited.time ? this.edited.time.value : this.getDefaultStartTime(),
        charter_package: this.edited.charterPackage,
        status: this.edited.status,
        cancelled: this.edited.cancelled,
        trip_id: this.thisBoatTripNameId[this.edited.charterPackage],//(this.edited.trip_id == null ? 0 : this.edited.trip_id),
        send_survey: false,
        details: this.edited.details,
        boat_id: this.edited.boatObject.id
      })
        .then(() => {
          if (this.edited.cancelled) {
            toastSuccess('Your booking has been canceled.')
          } else {
            toastSuccess('Your changes have been updated.')
          }

          this.$root.$emit('bv::hide::modal', 'edit-modal')
        })
        .catch(() => { toastFailure('Something went wrong.') })
        .finally(() => { this.isEditLoading = false })
    },
    dateDisabled(date) {
      return checkReservedDate(date, this.blockDates, this.edited, this.boatNamesTripsPictures, this.thisBoatTripNameId);
    },
    disableSelectedDate(){
      this.edited.date = null;
      this.datepickerPlaceholder = 'Please select a new date';
    },
    loadBlockDates() {
      if (this.edited.charterPackage) {
        const editedDate = this.edited.date
        this.datepickerPlaceholder = 'Loading...'
        this.isEditLoading = true
        this.isDatepickerDisabled = true
        axios.get(`api/bookings/block-dates/${this.edited.boatObject.id}/${this.edited.charterPackage}`)
          .then(response => {
            this.datepickerPlaceholder = 'Please choose the date';
            const blockDates = {}

            for (const date of response.data) {

              if( this.edited.date == date.date && this.edited.date != this.edited.beforeDate ){
                this.disableSelectedDate();
              }

              if (!blockDates[date.date]) {
                blockDates[date.date] = new Array();
              }

              blockDates[date.date].push(date);
            }

            this.blockDates = blockDates;
            this.isEditLoading = false;
            this.isDatepickerDisabled = false;

            if( this.dateDisabled(this.edited.date)  ){
              this.disableSelectedDate();
            }
          })
          .catch(() => { toastFailure('Something went wrong.') })
      }
    },
    isSelectable(time) {

      if (this.blockDates[this.edited.date]) {

        return (
                _.find(this.blockDates[this.edited.date], {'time': time}) !== undefined
                &&
                _.find(this.blockDates[this.edited.date], {'id': this.edited.id,'charter_package': this.edited.beforeCharterPackage,'time': time}) === undefined
            )
                ? false : true
      }
      return true
    },
    timesDisabled(option) {
      return option.selectable
    },
    confirmationMessage() {
      return this.$bvModal.msgBoxConfirm('Are you sure you want to cancel and not reschedule?', {
          title: 'Please Confirm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No, go back',
        })
    },
    rowPaymentHistory(params) {
      if (params.event.target.classList.contains('payment-status')) {
        this.getPaymentHistory(params.row.id)
      }
    },
    fieldPaymentHistory(bookingId) {
      this.getPaymentHistory(bookingId)
    },
    getPaymentHistory(bookingId) {
      this.$root.$emit('bv::show::modal', 'payment-history-modal')
      this.isPaymentHistoryLoading = true

      axios
        .get(
          `api/payment-history/${bookingId}`
        )
        .then(response => {
            this.paymentsHistory = response.data.data;
            this.isPaymentHistoryLoading = false;

            let paymentsHistoryPaidTMP = 0;
            let paymentsHistoryRefundedTMP = 0;
            this.paymentsHistory.map(function(pHistory){
                paymentsHistoryPaidTMP += pHistory.paid;
                paymentsHistoryRefundedTMP += pHistory.amount_refunded;
            });

            this.paymentsHistoryPaid = paymentsHistoryPaidTMP;
            this.paymentsHistoryRefunded = paymentsHistoryRefundedTMP;
        })
        .catch(() => {
          toastFailure("Something went wrong.")
        });
    },
    calculatePrice(booking) {

      const selectedBoatTripNameId = [];
      const thisBoatPrices = [];
      const thisBoatAdditionalPrices = [];

      this.boatNamesTripsPictures[booking.boat_id - 1].trips.map(function(trip){
        thisBoatPrices[trip.name] = trip.price;
        selectedBoatTripNameId[trip.name] = trip.id;
        thisBoatAdditionalPrices[trip.name] = trip.additional_price == null ? 0 : trip.additional_price;
      });

      if (booking.charter_package === HARBOR_CRUISE) return 800;
      if (booking.boat_id === TEASER_ID && booking.details > 6) {
        const additionalPrice = thisBoatAdditionalPrices[booking.charter_package];
        return thisBoatPrices[booking.charter_package] + additionalPrice * (booking.details - 6);
      }

      return thisBoatPrices[booking.charter_package];

    },
    dateChanged() {
      this.edited.time = null;
    },
    getDefaultStartTime() {
      return this.edited.boat === TEASER_ID ? "06:00:00" : "05:00:00";
    },
  },
}
</script>

<style lang="scss">
  .payment-paid {
    text-decoration-line: line-through;
    opacity: .5;
  }

  .payment-active {
    color: #28a5db;
  }

  .payment-remaining {
    color: #EE0000;
  }

  .payment-setup-card {
    background-color: rgba(255, 255, 255, .75);
    width: 93%;
    height: 94%;
    z-index: 1;
  }

  .profile-image {
    width: 140px;
    height: 140px;
    object-fit: cover;
  }
</style>
